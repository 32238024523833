import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'

declare let document: Document | any
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/download'
  },
  {
    path: '/download',
    name: 'download',
    meta: {
      title: '博大证券APP下载'
    },
    component: () => import(/* webpackChunkName: "download" */ '@/views/download/index.vue')
  },
  {
    path: '/mergePath',
    name: 'mergePath',
    meta: {
      title: '博大证券APP下载'
    },
    component: () => import(/* webpackChunkName: "mergePath" */ '@/views/mergePath/index.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})
export default router
