import axios from 'axios'
import { Toast } from 'vant'

const baseURL: any = import.meta.env.VITE_BASE_API
const service = axios.create({
  baseURL,
  timeout: 2000
})
service.interceptors.request.use(
  (response) => {
    return response
  },
  (error) => {
    return Promise.reject(error)
  }
)

service.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (error.response && error.response.data) {
      const code = error.response.status
      const msg = error.response.data.message
      Toast.fail(`Code:${code}, Mesage: ${msg}`)
    } else {
      Toast.fail(error)
    }
    return Promise.reject(error)
  }
)

export default service
